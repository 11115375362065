<template>
  <el-dialog
    title="选择项目"
    :visible.sync="bigDialogVisible"
    center
    class="certificationDialog big-dialog"
    width="900px"
  >
    <el-table
      ref="multipleTable"
      :data="itemList"
      border
      v-loading="listLoading"
      @selection-change="selectChange"
    >
      <el-table-column fixed type="selection" width="55" align="center" />
      <el-table-column
        fixed
        type="index"
        label="序号"
        width="70"
        align="center"
      />
      <el-table-column
        fixed
        prop="itemValName"
        label="比赛项目"
        align="center"
      />
      <el-table-column
        fixed
        prop="gender"
        label="性别组"
        width="120"
        align="center"
      >
        <template slot-scope="scope">
          {{
            scope.row.gender == 1
              ? "男子"
              : scope.row.gender == 2
              ? "女子"
              : scope.row.gender == 3
              ? "混合"
              : ""
          }}
        </template>
      </el-table-column>
      <el-table-column
        fixed
        prop="type"
        label="类型"
        width="120"
        align="center"
      >
        <template slot-scope="scope">
          {{
            scope.row.type == 1
              ? "个人"
              : scope.row.type == 2
              ? "双打"
              : scope.row.type == 3
              ? "团体"
              : ""
          }}
        </template>
      </el-table-column>
      <el-table-column
        fixed
        prop="signFee"
        label="报名费(元)"
        width="120"
        align="center"
      />
    </el-table>
    <span slot="footer" class="dialog-footer">
      <el-button @click="cancel">取 消</el-button>
      <el-button type="primary" @click="submitForm">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { competitionVerify } from "@/api/events";
export default {
  name: "addItem.vue",
  data() {
    return {
      bigDialogVisible: false,
      itemList: [],
      listLoading: false,
      selectData: [],
      personId: "",
    };
  },
  methods: {
    init(competitionItemFeeVos, personId) {
      this.bigDialogVisible = true;
      this.itemList = competitionItemFeeVos;
      this.personId = personId;
    },
    selectChange(e) {
      this.selectData = e;
    },
    cancel() {
      this.bigDialogVisible = false;
      this.$refs.multipleTable.clearSelection();
    },
    submitForm() {
      // let list = [];
      // this.selectData.forEach((el) => {
      //   list.push({
      //     category: el.category ? el.category : 1,
      //     itemFeeId: el.id,
      //     personId: this.personId,
      //   });
      // });
      // competitionVerify(list).then((res) => {
      //   if (res.code == 200) {
      //   } else {
      //     this.$message.error(res.msg);
      //   }
      // });
      this.$emit("refreshDataList", this.selectData);
      this.bigDialogVisible = false;
      this.$refs.multipleTable.clearSelection();
    },
  },
};
</script>

<style scoped lang="scss">
/deep/ .el-table .cell {
  line-height: 40px;
}
</style>