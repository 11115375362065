<template>
  <div class="wrapper-content">
    <el-steps :active="active" class="steps-bar" align-center>
      <el-step title="添加报名"></el-step>
      <el-step title="确认报名信息"></el-step>
      <el-step title="支付费用"></el-step>
      <el-step title="报名成功"></el-step>
    </el-steps>
    <div class="steps-content">
      <div v-if="active == 0" class="steps-content-1">
        <div class="content-bg1">
          <el-tabs v-model="tabNum" class="detail-tab" @tab-click="handleClick">
            <el-tab-pane label="按项目报名" name="1">
              <div>
                <el-table
                    ref="multipleTable"
                    :key="tableKey1"
                    :data="competitionItemFeeVos"
                    border
                >
                  <el-table-column
                      fixed
                      type="index"
                      label="序号"
                      width="70"
                      align="center"
                  />
                  <el-table-column
                      fixed
                      prop="itemValName"
                      label="比赛项目"
                      align="center"
                      width="150"
                  />
                  <el-table-column
                      fixed
                      label="性别组"
                      align="center"
                      width="80"
                  >
                    <template slot-scope="scope">
                      {{
                        scope.row.gender == 1
                            ? "男子"
                            : scope.row.gender == 2
                            ? "女子"
                            : scope.row.gender == 3
                                ? "混合"
                                : ""
                      }}
                    </template>
                  </el-table-column>
                  <el-table-column fixed label="类型" align="center" width="80">
                    <template slot-scope="scope">
                      {{
                        scope.row.type == 1
                            ? "个人"
                            : scope.row.type == 2
                            ? "双打"
                            : scope.row.type == 3
                                ? "团体"
                                : ""
                      }}
                    </template>
                  </el-table-column>
                  <el-table-column
                      fixed
                      label="报名费"
                      align="center"
                      width="80"
                  >
                    <template slot-scope="scope">
                      {{
                        scope.row.collectFee == 1 ? scope.row.signFee : "免费"
                      }}
                    </template>
                  </el-table-column>
                  <el-table-column fixed label="参赛人员" header-align="center">
                    <template slot-scope="scope">
                      <span
                          v-for="tag in scope.row.competitionSignCoachForms"
                          :key="tag.id"
                      >
                        <el-tag
                            closable
                            type="info"
                            effect="plain"
                            style="margin: 4px"
                            @close="handleClose(tag, scope.$index, scope.row.id)"
                        >{{ tag.name }}</el-tag
                        >
                      </span>
                    </template>
                  </el-table-column>
                  <el-table-column fixed label="人数" align="center" width="80">
                    <template slot-scope="scope">
                      {{
                        scope.row.competitionSignCoachForms &&
                        scope.row.competitionSignCoachForms.length
                            ? scope.row.competitionSignCoachForms.length
                            : ""
                      }}
                    </template>
                  </el-table-column>
                  <el-table-column
                      fixed
                      label="操作"
                      width="100"
                      align="center"
                  >
                    <template slot-scope="scope">
                      <el-button
                          type="text"
                          @click="addPersonnel(scope.row.id, false, true)"
                      >添加人员
                      </el-button
                      >
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </el-tab-pane>
            <el-tab-pane label="按人员报名" name="2">
              <div>
                <el-form :inline="true" :model="queryParams" label-width="80px">
                  <el-form-item
                      label="姓名:"
                      prop="name"
                      style="margin-bottom: 0"
                  >
                    <el-input
                        type="age"
                        v-model="queryParams.name"
                        placeholder="请输入姓名"
                        autocomplete="off"
                    ></el-input>
                  </el-form-item>
                  <el-form-item
                      label="证件号:"
                      prop="certificatesNumber"
                      style="margin-bottom: 0"
                  >
                    <el-input
                        type="age"
                        v-model="queryParams.certificatesNumber"
                        placeholder="请输入证件号"
                        autocomplete="off"
                    ></el-input>
                  </el-form-item>
                  <el-form-item
                      label="手机号:"
                      prop="mobilePhone"
                      style="margin-bottom: 0"
                  >
                    <el-input
                        type="age"
                        v-model="queryParams.mobilePhone"
                        placeholder="请输入手机号"
                        autocomplete="off"
                    ></el-input>
                  </el-form-item>
                  <el-form-item style="margin-bottom: 0">
                    <el-button type="primary" plain @click="submitForm()"
                    >搜索
                    </el-button
                    >
                    <el-button @click="resetForm()">重置</el-button>
                    <el-button
                        style="margin-bottom: 10px"
                        type="primary"
                        @click="addPersonnel('', false, false)"
                    >添加人员
                    </el-button
                    >
                  </el-form-item>
                </el-form>
                <el-table
                    ref="multipleTable2"
                    :key="tableKey2"
                    :data="participantData"
                    border
                >
                  <el-table-column
                      fixed
                      type="index"
                      label="序号"
                      width="70"
                      align="center"
                  />
                  <el-table-column
                      fixed
                      prop="name"
                      label="姓名"
                      width="150"
                      align="center"
                  />
                  <el-table-column
                      fixed
                      prop="certificatesNumber"
                      label="证件号"
                      width="200"
                      align="center"
                  />
                  <el-table-column
                      fixed
                      prop="mobilePhone"
                      label="手机号"
                      width="120"
                      align="center"
                  />
                  <el-table-column fixed label="参赛项目" align="center">
                    <template slot-scope="scope">
                      <span
                          v-for="(item, index) in scope.row.itemList"
                          :key="index"
                      >
                        <el-tag
                            closable
                            type="info"
                            effect="plain"
                            style="margin: 0 4px"
                            @close="
                            handleCloseItem(
                              scope.$index,
                              index,
                              item,
                              scope.row.id
                            )
                          "
                        >{{ item.itemValName }}</el-tag
                        >
                      </span>
                    </template>
                  </el-table-column>
                  <el-table-column
                      fixed
                      label="操作"
                      width="100"
                      align="center"
                  >
                    <template slot-scope="scope">
                      <el-button type="text" @click="addItem(scope.row.id)"
                      >添加项目
                      </el-button
                      >
                    </template>
                  </el-table-column>
                </el-table>
                <el-pagination
                    class="page-bar"
                    background
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="queryParams.currentPage"
                    :page-sizes="[10, 20, 50, 100]"
                    :page-size="queryParams.pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="total"
                >
                </el-pagination>
              </div>
            </el-tab-pane>
          </el-tabs>

          <div class="flex">
            <div class="flex1">
              <el-input
                  v-model="teamName"
                  placeholder="输入您的参赛队名称"
                  style="width: 280px"
              ></el-input>
              <span style="font-size: 14px; color: #666; margin-left: 5px"
              >(如果您是单位队伍负责人，请输入您所在的单位或队伍名称，如XXX学校、XXX村民运动会)</span
              >
              <!--            <el-button type="primary" style="margin-left: 10px">保存</el-button>-->
            </div>
            <div>
              <el-button type="primary" @click="addPersonnel('', true, true)"
              >添加工作人员
              </el-button
              >
            </div>
          </div>
        </div>
        <!--        <div>（如果您是单位队伍负责人，请输入您所在的单位或队伍名称，如XXX学校、XXX村民运动会）</div>-->
        <div class="content-bg2">
          <el-table :data="totalData" border :highlight-current-row="true">
            <el-table-column
                fixed
                type="index"
                label="序号"
                width="70"
                align="center"
            />
            <el-table-column
                fixed
                prop="category"
                label="人员类型"
                width="120"
                align="center"
            >
              <template slot-scope="scope">
                <span v-if="scope.row.category == 1">运动员</span>
                <span v-else-if="scope.row.category == 2">领队</span>
                <span v-else-if="scope.row.category == 3">教练</span>
                <span v-else-if="scope.row.category == 4">工作人员</span>
                <span v-else>其他</span>
              </template>
            </el-table-column>
            <el-table-column
                fixed
                prop="name"
                label="姓名"
                width="150"
                align="center"
            />
            <el-table-column
                fixed
                prop="gender"
                label="性别组"
                width="120"
                align="center"
            >
              <template slot-scope="scope">
                <span>{{
                    scope.row.gender == 1
                        ? "男"
                        : scope.row.gender == 2
                        ? "女"
                        : ""
                  }}</span>
              </template>
            </el-table-column>
            <el-table-column
                fixed
                prop="itemValName"
                label="报名项目"
                align="center"
            />
            <el-table-column
                fixed
                prop="type"
                label="类型"
                width="80"
                align="center"
            >
              <template slot-scope="scope">
                {{
                  scope.row.type == 1
                      ? "个人"
                      : scope.row.type == 2
                      ? "双打"
                      : scope.row.type == 3
                          ? "团体"
                          : ""
                }}
              </template>
            </el-table-column>
            <el-table-column fixed label="操作" width="100" align="center">
              <template slot-scope="scope">
                <el-button
                    type="text"
                    class="btn-delCertResume"
                    @click="
                    delPeople(
                      scope.row,
                      scope.row.index,
                      scope.$index,
                      scope.row.otherIndex
                    )
                  "
                >删除
                </el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>

      <div v-show="active == 1 || active == 2" class="steps-content-2">
        <div class="content-bg1">
          <div class="step-title">赛事信息</div>
          <div class="step-detail">
            <div class="step-content-info">
              <h2>
                {{ dataInfo.name }}
                <el-tag
                    v-if="dataInfo.status == 5"
                    type="success"
                    effect="dark"
                    size="mini"
                >筹备中
                </el-tag
                >
                <el-tag
                    v-if="dataInfo.status == 7"
                    type="danger"
                    effect="dark"
                    size="mini"
                >报名中
                </el-tag
                >
                <el-tag
                    v-if="dataInfo.status == 8"
                    type="info"
                    effect="dark"
                    size="mini"
                >报名已结束
                </el-tag
                >
                <el-tag v-if="dataInfo.status == 9" effect="dark" size="mini"
                >比赛中
                </el-tag
                >
                <el-tag
                    v-if="dataInfo.status == 10"
                    type="info"
                    effect="dark"
                    size="mini"
                >比赛已结束
                </el-tag
                >
              </h2>
              <p>
                <span class="name">比赛时间：</span>
                <span class="value"
                >{{ formatDateYMD(dataInfo.gameStartTime) }} -{{
                    formatDateYMD(dataInfo.gameEndTime)
                  }}</span
                >
              </p>
              <p>
                <span class="name">比赛地点：</span>
                <span class="value"
                >{{ dataInfo.province }}{{ dataInfo.city }}{{
                    dataInfo.area
                  }}{{ dataInfo.address }}</span
                >
              </p>
              <p>
                <span class="name">报名费用：</span>
                <span v-if="dataInfo.collectFee == 1" class="value danger"
                >¥<i class="fz22">{{ dataInfo.signFee }}</i></span
                >
                <span class="btn-delCertResume" v-else
                ><i class="fz22">免费</i></span
                >
              </p>
              <p
                  v-for="(item, index) in dataInfo.competitionCompanyVos"
                  :key="index"
              >
                <span class="name">{{ item.title }}：</span>
                <span class="value">{{ item.content.join("、") }}</span>
              </p>
            </div>
          </div>
        </div>

        <div class="content-bg2">
          <div class="step-title">报名信息</div>
          <div class="gzry">
            <span v-if="otherPeopleLD.length > 0"
            >领队：{{ otherPeopleLD.join("、") }}</span
            >
            <span v-if="otherPeopleJL.length > 0"
            ><span v-if="otherPeopleLD.length > 0">，</span>教练：{{
                otherPeopleJL.join("、")
              }}</span
            >
            <span v-if="otherPeopleGZRY.length > 0"
            ><span v-if="otherPeopleJL.length > 0">，</span>工作人员：{{
                otherPeopleGZRY.join("、")
              }}</span
            >
            <span v-if="otherPeopleQT.length > 0"
            ><span v-if="otherPeopleGZRY.length > 0">，</span>其他：{{
                otherPeopleQT.join("、")
              }}</span
            >
          </div>
          <el-table
              :data="enrollInfo"
              class="tab_apply"
              style="margin-top: 5px"
          >
            <!--          <el-table-column type="selection" width="55" />-->
            <el-table-column
                fixed
                type="index"
                label="序号"
                width="70"
                align="center"
            />
            <el-table-column
                prop="itemValName"
                label="报名项目"
                width="200"
                align="center"
            />
            <el-table-column
                prop="gender"
                label="性别组"
                width="100"
                align="center"
            >
              <template slot-scope="scope">
                {{
                  scope.row.gender == 1
                      ? "男子"
                      : scope.row.gender == 2
                      ? "女子"
                      : scope.row.gender == 3
                          ? "混合"
                          : ""
                }}
              </template>
            </el-table-column>
            <el-table-column
                prop="type"
                label="项目类型"
                width="100"
                align="center"
            >
              <template slot-scope="scope">
                {{
                  scope.row.type == 1
                      ? "个人"
                      : scope.row.type == 2
                      ? "双打"
                      : scope.row.type == 3
                          ? "团体"
                          : ""
                }}
              </template>
            </el-table-column>
            <el-table-column
                prop="feeRule"
                label="收费规则"
                width="100"
                align="center"
            >
              <template slot-scope="scope">
                {{ scope.row.feeRule == 1 ? "按人数" : "按项目" }}
              </template>
            </el-table-column>
            <el-table-column prop="itemNames" label="报名人员" align="center">
              <template slot-scope="scope">
                <span v-for="(tag, index) in scope.row.competitionSignCoachForms" :key="tag.id">
                  <span>{{ tag.name }}
                    <i v-if=" index < scope.row.competitionSignCoachForms.length - 1">、</i>
                    <i v-else></i>
                  </span>
                </span>
              </template>
            </el-table-column>
            <el-table-column prop="signFee" label="原价(元)" width="100" align="center">
              <template slot-scope="scope">
                <template v-if="scope.row.collectFee == 0">0</template>
                <template v-else>
                  {{
                    scope.row.feeRule == 1 ? scope.row.competitionSignCoachForms.length * scope.row.signFee : scope.row.signFee
                  }}
                </template>
              </template>
            </el-table-column>
            <el-table-column label="实付(元)" width="150" align="center">
              <template slot-scope="scope">
                <template v-if="scope.row.collectFee == 0">0</template>
                <template v-else>
                  <span v-if="scope.row.feeRule == 2">{{ isMember ? scope.row.memberFee : scope.row.signFee }}</span>
                  <span v-else>{{
                      (isMember ? scope.row.memberFee : scope.row.signFee) * scope.row.competitionSignCoachForms.length
                    }}</span>
                </template>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="content-bg1" style="margin-top: 20px; margin-bottom: 0">
          <div class="step-title">费用信息</div>
          <div class="feeBox">
            <div class="name">按人数收费：<span class="num">{{ peopleFee.num }}</span>人共<span class="fee">{{
                peopleFee.fee
              }}元</span></div>
            <div class="name">按项目收费：<span class="num">{{ itemFee.num }}</span>人共<span class="fee">{{
                itemFee.fee
              }}元</span></div>
            <div class="name total">费用合计：<span class="fee">{{ (peopleFee.fee * 100 + itemFee.fee * 100) / 100 }}元</span>
            </div>
          </div>
        </div>
        <Pay v-if="payVisible" ref="Pay"/>
      </div>
      <div v-if="active == 4" class="steps-content-4">
        <pay-result
            :payResult="payResult"
            :url="`/events-orderinfo?itemId=${gItem.itemId}`"
        />
      </div>
    </div>
    <div class="btn-steps">
      <el-button v-if="active == 0" @click="cancel">取消</el-button>
      <el-button v-if="active != 0" @click="prev">上一步</el-button>
      <el-button
          type="primary"
          :disabled="nextchecked"
          :loading="btnLoading"
          @click="next"
      >下一步
      </el-button
      >
    </div>
    <AddPeople
        v-show="addPeopleVisible"
        ref="AddPeople"
        @close="addPeopleVisible = false"
        @refreshDataList="addPeopleData"
        @refreshClose="dialogClose"
    />
    <AddItem
        v-show="addItemVisible"
        ref="AddItem"
        @close="addItemVisible = false"
        @refreshDataList="addItemData"
    />
  </div>
</template>

<script>
import Pay from "@/pages/payment/pay.vue";
import payResult from "@/pages/payment/result.vue";
import AddPeople from "./addPeople";
import AddItem from "./addItem";
import {
  enrollConfirm,
  getPersonnel,
  getCompetitionItemDetails,
  competitionVerify,
  competitionPersonnelApplyOrder
} from "@/api/events";
import {memberIsMember} from "../../api/partners";

export default {
  name: "events-signup",
  components: {
    payResult,
    AddPeople,
    AddItem,
    Pay,
  },
  data() {
    return {
      addPeopleVisible: false,
      addItemVisible: false,
      active: 0,
      bigDialogTitle: "",
      bigDialogVisible: false,
      addPersonal: false,
      nextchecked: true,
      gItem: {},
      payResult: "",
      eInfo: {},
      tabNum: "1",
      tabLabel: "",
      competitionItemFeeVos: [],
      dialogTableVisible: false,
      participantData: [],
      participantLoading: false,
      competitionPersonnelEditForm: {
        certificatesNumber: "",
        gender: "",
        mobilePhone: "",
        name: "",
        edit: true,
      },
      tableKey: "",
      addId: "",
      selectData: [],
      tableKey1: "",
      tableKey2: "",
      totalData: [],
      otherPeople: [],
      dataInfo: "",
      teamName: "",
      otherPeopleLD: [],
      otherPeopleJL: [],
      otherPeopleGZRY: [],
      otherPeopleQT: [],
      enrollInfo: [],
      itemFee: {num: 0, fee: 0},
      peopleFee: {num: 0, fee: 0},
      btnLoading: false,
      addItemId: "",
      isGetPeople: true,
      payVisible: false,
      queryParams: {
        competitionId: this.$route.query.id,
        name: "",
        certificatesNumber: "",
        mobilePhone: "",
        currentPage: 1,
        pageSize: 10,
      },
      total: 0,
      isMember: false, //true表示会员，false表示不是会员
      isPay:false,
      flag:false,
      isClickPay:false,
      orderNo:''
    };
  },
  mounted() {
    // this.dataInfo = JSON.parse(this.$route.query.dataInfo)
    // this.competitionItemFeeVos = this.dataInfo.competitionItemFeeVos
    // console.log(this.$route.query.id)
    this.competitionId = this.$route.query.id;
    this.getCompetitionItemDetails(this.competitionId);
    this.getPersonnel(this.competitionId);
  },
  methods: {
    //获取详情
    getCompetitionItemDetails(competitionId) {
      const self = this;
      self.$store.commit("showLoading");
      getCompetitionItemDetails(competitionId).then((res) => {
        if (res.code == 200) {
          self.competitionCompanyVos = res.data.competitionCompanyVos;
          self.competitionExplainVos = res.data.competitionExplainVos;
          self.competitionItemFeeVos = res.data.competitionItemFeeVos;
          self.dataInfo = res.data;
          // self.myJoinCompetitionTows();
          self.$nextTick(() => {
            this.getMemberIsMember(res.data.tenantId);
          });
        } else {
          self.$message(res.msg);
        }
        self.$store.commit("hideLoading");
      });
    },
    //查询当前用户是否为会员
    getMemberIsMember(tenantId) {
      let userId = window.localStorage.getItem("user") && JSON.parse(window.localStorage.getItem("user")).userInfo.userId || "";
      memberIsMember(tenantId, userId).then((res) => {
        if (res.code == 200) {
          this.isMember = res.data;
        }
      });
    },
    //查询
    submitForm() {
      this.getPersonnel();
    },
    //重置
    resetForm() {
      this.queryParams = {
        name: "",
        certificatesNumber: "",
        mobilePhone: "",
        currentPage: 1,
        pageSize: 10,
      };
      this.getPersonnel();
    },
    handleSizeChange(val) {
      this.queryParams.pageSize = val;
      this.getPersonnel();
    },
    handleCurrentChange(val) {
      this.queryParams.currentPage = val;
      this.getPersonnel();
    },
    // 删除参赛人员
    handleClose(tag, index, id) {
      this.$confirm("您确定要删除该参赛人员吗？", "提示", {
        type: "warning",
      }).then(() => {
        let competitionSignCoachForms =
            this.competitionItemFeeVos[index].competitionSignCoachForms;
        competitionSignCoachForms.splice(
            competitionSignCoachForms.indexOf(tag),
            1
        );
        this.tableKey1 = Math.random();
        this.returnPeopleItem(1);
      });
    },
    // 添加人员
    addPersonnel(id, isCategory, btnSelect) {
      this.isGetPeople = btnSelect;
      this.addId = id;
      this.addPeopleVisible = true;
      this.$nextTick(() => {
        this.$refs.AddPeople.init(
            this.competitionItemFeeVos,
            isCategory,
            btnSelect,
            "events",
            this.competitionId,
            id
        );
      });
    },
    prev() {
      if (this.active-- == 0) this.active = 0;
      this.btnLoading = false;
      this.flag = false
      this.isPay = false
      this.isNext();
    },
    next() {
      if (this.active == 0) {
        let list = [];
        this.totalData.forEach((el) => {
          list.push({
            category: el.category,
            itemFeeId: el.itemId,
            personId: el.id,
          });
        });
        competitionVerify(list).then((res) => {
          if (res.code == 200) {
            this.active++;
            this.collectEnroll();
          } else {
            this.$message.error(res.msg);
          }
        });
      } else if (this.active == 1) {
        this.nextchecked = true;
        this.btnLoading = true;
        this.enrollConfirmFun();
      } else if (this.active == 2) {
      } else if (this.active == 3) {
      } else if (this.active > 3) {
      }
    },
    handleClick(tab, event) {
      this.tabNum = tab.name;
      this.nextchecked = true;
      if (tab.label != this.tabLabel) {
        this.totalData = [];
        this.otherPeople = [];
        this.tabLabel = tab.label;
        this.competitionItemFeeVos.forEach((item) => {
          item.competitionSignCoachForms = [];
        });
        this.participantData.forEach((item) => {
          this.$delete(item, "itemList");
        });
      }
    },
    cancel() {
      this.$router.go(-1);
    },
    getstep(params) {
      if (params && params.active) {
        this.active = params.active;
      } else if (this.active == 2) {
        this.btnLoading = false;
        this.active--;
      } else {
        this.active--;
      }
    },
    //选择人员回显
    addPeopleData(data, isCategory) {
      if (isCategory) {
        let arr = this.otherPeople.concat(data);
        this.otherPeople = this.fn1(arr);
      } else {
        this.competitionItemFeeVos.forEach((el, index) => {
          if (el.id == this.addId) {
            let arr = [];
            if (this.competitionItemFeeVos[index].competitionSignCoachForms) {
              arr =
                  this.competitionItemFeeVos[
                      index
                      ].competitionSignCoachForms.concat(data);
            } else {
              arr = data;
            }
            arr = this.fn1(arr);
            this.$set(
                this.competitionItemFeeVos[index],
                "competitionSignCoachForms",
                arr
            );
          }
        });
      }
      this.$nextTick(() => {
        this.returnPeopleItem(this.tabNum);
      });
    },
    //去重
    fn1(tempArr) {
      for (let i = 0; i < tempArr.length; i++) {
        for (let j = i + 1; j < tempArr.length; j++) {
          if (tempArr[i].id == tempArr[j].id) {
            tempArr.splice(j, 1);
            j--;
          }
        }
      }
      return tempArr;
    },
    //选择项目
    addItem(id) {
      this.addItemId = id;
      this.addItemVisible = true;
      this.$nextTick(() => {
        this.$refs.AddItem.init(this.competitionItemFeeVos, id);
      });
    },
    //选择项目回显
    addItemData(data) {
      this.participantData.forEach((item, index) => {
        if (item.id == this.addItemId) {
          let arr = [];
          if (this.participantData[index].itemList) {
            arr = this.participantData[index].itemList.concat(data);
          } else {
            arr = data;
          }
          arr = this.fn1(arr);
          this.$set(this.participantData[index], "itemList", arr);
        }
      });
      this.$nextTick(() => {
        this.returnPeopleItem(2);
      });
    },
    //删除项目
    handleCloseItem(rowIndex, index, item, id) {
      this.$confirm("您确定要删除该项目吗？", "提示", {
        type: "warning",
      }).then(() => {
        this.participantData[rowIndex].itemList.splice(index, 1);
        this.returnPeopleItem(2);
      });
    },
    //显示报名人员的报项和类型列表
    returnPeopleItem(tab) {
      this.totalData = [];
      if (tab == 1) {
        this.competitionItemFeeVos.forEach((item, index) => {
          if (item.competitionSignCoachForms) {
            item.competitionSignCoachForms.forEach((dates) => {
              this.totalData.push({
                certificatesNumber: dates.certificatesNumber,
                gender: dates.gender,
                id: dates.id,
                mobilePhone: dates.mobilePhone,
                name: dates.name,
                category: 1,
                itemValName: item.itemValName,
                type: item.type,
                index: index,
                itemId: item.id,
              });
            });
          }
        });
      } else if (tab == 2) {
        this.participantData.forEach((item, index) => {
          if (item.itemList) {
            item.itemList.forEach((dates) => {
              this.totalData.push({
                certificatesNumber: item.certificatesNumber,
                gender: item.gender,
                id: item.id,
                mobilePhone: item.mobilePhone,
                name: item.name,
                category: 1,
                itemValName: dates.itemValName,
                type: dates.type,
                itemId: dates.id,
                index: index,
              });
            });
          }
        });
      }
      if (this.otherPeople.length > 0) {
        this.otherPeople.forEach((item, index) => {
          this.totalData.push({
            certificatesNumber: item.certificatesNumber,
            gender: item.gender,
            id: item.id,
            mobilePhone: item.mobilePhone,
            name: item.name,
            category: item.category,
            itemValName: "",
            type: "",
            otherIndex: index,
          });
        });
      }
      this.isNext();
    },
    //汇总报名人员和费用
    collectEnroll() {
      this.otherPeopleLD = [];
      this.otherPeopleJL = [];
      this.otherPeopleGZRY = [];
      this.otherPeopleQT = [];
      this.enrollInfo = [];
      if (this.tabNum == 1) {
        this.competitionItemFeeVos.forEach((item) => {
          if (
              item.competitionSignCoachForms &&
              item.competitionSignCoachForms.length > 0
          ) {
            this.enrollInfo.push({
              collectFee: item.collectFee,
              competitionSignCoachForms: item.competitionSignCoachForms,
              feeRule: item.feeRule,
              gender: item.gender,
              id: item.id,
              itemValName: item.itemValName,
              memberFee: item.memberFee,
              signFee: item.signFee,
              sort: item.sort,
              type: item.type,
            });
            this.feeTotal();
          }
        });
      } else if (this.tabNum == 2) {
        this.competitionItemFeeVos.forEach((item, index) => {
          this.competitionItemFeeVos[index].competitionSignCoachForms = [];
          this.participantData.forEach((dates) => {
            if (dates.itemList && dates.itemList.length > 0) {
              dates.itemList.forEach((ele) => {
                if (item.id == ele.id) {
                  this.competitionItemFeeVos[
                      index
                      ].competitionSignCoachForms.push({
                    category: dates.category,
                    certificatesNumber: dates.certificatesNumber,
                    gender: dates.gender,
                    id: dates.id,
                    mobilePhone: dates.mobilePhone,
                    name: dates.name,
                  });
                }
              });
            }
          });

          if (
              item.competitionSignCoachForms &&
              item.competitionSignCoachForms.length > 0
          ) {
            this.enrollInfo.push({
              collectFee: item.collectFee,
              competitionSignCoachForms: item.competitionSignCoachForms,
              feeRule: item.feeRule,
              gender: item.gender,
              id: item.id,
              itemValName: item.itemValName,
              memberFee: item.memberFee,
              signFee: item.signFee,
              sort: item.sort,
              type: item.type,
            });
            this.feeTotal();
          }
        });
      }

      if (this.otherPeople.length > 0) {
        this.otherPeople.forEach((item) => {
          if (item.category == 2) {
            this.otherPeopleLD.push(item.name);
          } else if (item.category == 3) {
            this.otherPeopleJL.push(item.name);
          } else if (item.category == 4) {
            this.otherPeopleGZRY.push(item.name);
          } else if (item.category == 5) {
            this.otherPeopleQT.push(item.name);
          }
        });
      }
    },
    //删除人员
    delPeople(row, index, rowIndex, otherIndex) {
      if (row.category == 1) {
        //运动员
        this.$confirm("您确定要删除该参赛人员吗？", "提示", {
          type: "warning",
        }).then(() => {
          if (this.tabNum == 1) {
            let competitionSignCoachForms =
                this.competitionItemFeeVos[row.index].competitionSignCoachForms;
            competitionSignCoachForms.forEach((item, idxs) => {
              if (row.id == item.id) {
                competitionSignCoachForms.splice(idxs, 1);
              }
            });
          } else if (this.tabNum == 2) {
            let itemList = this.participantData[index].itemList;
            itemList.forEach((item, idxs) => {
              if (row.itemId == item.id) {
                itemList.splice(idxs, 1);
              }
            });
          }
          this.totalData.splice(rowIndex, 1);
          this.tableKey1 = Math.random();
          this.isNext();
        });
      } else {
        //领队、教练、工作人员、其他
        this.$confirm("您确定要删除该参赛人员吗？", "提示", {
          type: "warning",
        }).then(() => {
          this.totalData.splice(rowIndex, 1);
          this.otherPeople.splice(otherIndex, 1);
          this.isNext();
        });
      }
    },
    //判断是否可以点击下一步，注必须要有运动员才能进行下一步
    isNext() {
      if (this.totalData.length <= 0) {
        return (this.nextchecked = true);
      }
      for (let i = 0; i < this.totalData.length; i++) {
        if (this.totalData[i].category == 1) {
          return (this.nextchecked = false);
        } else {
          return (this.nextchecked = true);
        }
      }
    },
    //计算费用
    feeTotal() {
      this.itemFee = {num: 0, fee: 0};
      this.peopleFee = {num: 0, fee: 0};
      this.enrollInfo.forEach((item) => {
        if (item.feeRule == 1) {
          this.peopleFee.num = this.peopleFee.num + Number(item.competitionSignCoachForms.length);
          if (item.collectFee == 0) {
            this.peopleFee.fee = 0
          } else {
            this.peopleFee.fee = this.peopleFee.fee + Number(item.competitionSignCoachForms.length * (this.isMember ? item.memberFee : item.signFee));
          }
        } else if (item.feeRule == 2) {
          this.itemFee.num = this.itemFee.num + Number(item.competitionSignCoachForms.length);
          if (item.collectFee == 0) {
            this.peopleFee.fee = 0
          } else {
            this.itemFee.fee = this.itemFee.fee + Number(this.isMember ? item.memberFee : item.signFee);
          }
        }
      });
    },
    //确认报名
    enrollConfirmFun() {
      let otherPeople = [];
      let enrollInfo = [];
      this.otherPeople.forEach((item) => {
        otherPeople.push({
          category: item.category,
          personnelId: item.id,
        });
      });
      this.enrollInfo.forEach((item) => {
        let arr = [];
        item.competitionSignCoachForms.forEach((dates) => {
          arr.push({
            category: dates.category,
            personnelId: dates.id,
          });
        });
        enrollInfo.push({
          competitionSignCoachForms: arr,
          itemFeeId: item.id,
        });
      });
      let params = {
        competitionId: this.dataInfo.id,
        competitionSignCoachForms: otherPeople,
        competitionSignPersonnelForms: enrollInfo,
        teamName: this.teamName,
      };
      let data = {
        competitionIds: [this.dataInfo.id],
        payChannel:1,//支付渠道：1微信 2支付宝 3银联
      }
      this.$store.commit("showLoading");
      this.btnLoading = true
      if(!this.isClickPay){
        if(this.flag){
          if(this.isPay){
            competitionPersonnelApplyOrder(data).then(res=>{
              if(this.isPay){
                this.orderNo = res.data.orderNo
                this.payVisible = true;
                this.isClickPay = true
                this.$nextTick(() => {
                  this.$refs.Pay.init(res.data.orderNo, 2);
                });
              }else {
                this.$router.push({
                  path: "/result",
                  query: {type: "success", memberPrice: "1"},
                });
              }
            }).catch(()=>{
              this.btnLoading = false
              this.$store.commit("hideLoading");
            })
          }else {
            this.$router.push({
              path: "/result",
              query: {type: "success", memberPrice: "1"},
            });
          }
        }else {
          enrollConfirm(params).then((res) => {
            if (res.code == 200) {
              this.nextchecked = false;
              this.btnLoading = false;
              this.flag = true
              this.isPay = res.data.isPay
              if (this.isPay) {
                competitionPersonnelApplyOrder(data).then(res=>{
                  this.payVisible = true;
                  this.orderNo = res.data.orderNo
                  this.isClickPay = true
                  this.$nextTick(() => {
                    this.$refs.Pay.init(res.data.orderNo, 2);
                  });
                }).catch(()=>{
                  this.btnLoading = false
                  this.$store.commit("hideLoading");
                })
              } else {
                this.$router.push({
                  path: "/result",
                  query: {type: "success", memberPrice: "1"},
                });
              }
            } else {
              this.nextchecked = false;
              this.btnLoading = false;
              this.$message(res.msg);
              this.$store.commit("hideLoading");
            }
          }).catch(() => {
            this.nextchecked = false;
            this.btnLoading = false;
            this.$store.commit("hideLoading");
            return this.$message({
              message: "请求出错，请重新再试！",
              type: "error",
            });
          });
        }
      }else {
        this.$refs.Pay.init(this.orderNo, 2);
        this.btnLoading = false;
        this.nextchecked = false;
      }

      // enrollConfirm(params).then((res) => {
      //   if (res.code == 200) {
      //     this.nextchecked = false;
      //     this.btnLoading = false;
      //     // this.active ++
      //     if (res.data.isPay) {
      //       this.payVisible = true;
      //       this.$nextTick(() => {
      //         this.$refs.Pay.init(res.data.orderNo, 2);
      //       });
      //     } else {
      //       this.$router.push({
      //         path: "/result",
      //         query: {type: "success", memberPrice: "1"},
      //       });
      //     }
      //   } else {
      //     this.nextchecked = false;
      //     this.btnLoading = false;
      //     this.$message(res.msg);
      //   }
      // }).catch(() => {
      //   this.nextchecked = false;
      //   this.btnLoading = false;
      //   return this.$message({
      //     message: "请求出错，请重新再试！",
      //     type: "error",
      //   });
      // });
    },
    //关闭添加人员弹窗重新比对人员信息
    dialogClose(data) {
      if (!this.isGetPeople) {
        this.participantData.forEach((item) => {
          data.forEach((dates) => {
            if (item.id == dates.id) {
              dates.itemList = item.itemList;
            }
          });
        });
        this.participantData = data;
      }
    },
    // 获取参赛人员列表
    getPersonnel() {
      this.participantLoading = true;
      getPersonnel(this.queryParams).then((res) => {
        res.data.list.forEach((item) => {
          item.category = 1;
        });
        this.participantData = res.data.list;
        this.total = res.data.pagination.total;
        this.participantLoading = false;
        this.tableKey2 = Math.random();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.flex {
  display: flex;
  margin-top: 10px;

  .flex1 {
    flex: 1;
  }
}

.btn-delCertResume {
  color: #f56c6c;
}

.detail-tab {
  width: 100%;
  min-height: 100px;
  color: #555;

  /deep/ .el-tabs__nav-wrap::after {
    height: 1px;
    background-color: #e8e8e8;
  }
}

.steps-bar {
  width: 100%;
  height: 52px;
  padding: 14px 0;
  margin-top: 40px;
  background: url("../../assets/image/detail-signupbg.png") center no-repeat;
}

.steps-content {
  width: 100%;
  padding-top: 30px;

  .steps-content-1 {
    //min-height: 400px;
  }

  .tab_apply {
    width: 100%;
    margin-top: 30px;

    /deep/ .el-table__header th {
      background: #fafafa;
      border-top: 1px solid #e9ecee;
      //padding: 4px;
      font-weight: normal;
    }
  }

  .table-btn_bar {
    width: 100%;
    height: 50px;
    line-height: 50px;
    background: #ffffff;
    border-bottom: 1px solid #e9ecee;
    padding: 0 12px;
    box-sizing: border-box;

    .apply-num {
      padding-left: 16px;
      font-size: 14px;
      color: #555555;
    }

    .all-price {
      text-align: right;
      font-size: 16px;
      color: #555;

      .fz22 {
        font-size: 22px;
      }
    }
  }
}

.btn-steps {
  width: 100%;
  padding: 60px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.add-apply_item {
  .search-bar {
    padding-top: 0;
  }
}

.step-content-info {
  padding-left: 20px;
  line-height: 30px;
  font-size: 14px;
  color: #555;

  h2 {
    font-size: 20px;
    color: #333;
    font-weight: bold;
  }

  .fz22 {
    font-size: 22px;
  }
}

.steps-content-2,
.steps-content-3 {
  border: 1px solid #e4e4e4;
  padding: 20px;
  background: #ffffff;
}

.step-title {
  font-size: 22px;
  color: #333333;
  line-height: 26px;
  font-weight: bold;
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
}

.step-order-info {
  background: #f8f8f8;
  padding: 10px 20px;
  margin: 20px 0;
  line-height: 34px;
  font-size: 14px;
  color: #555;
  display: flex;
  flex-wrap: wrap;

  li {
    width: 50%;
  }
}

// 弹出层
.certificationDialog {
  .bold {
    font-weight: bold;
  }

  /deep/ .el-form-item__label {
    text-align: left;
  }

  /deep/ .el-dialog {
    width: 586px;
    box-shadow: 0px 9px 40px 0px rgba(75, 75, 75, 0.12);
    border-radius: 10px;
  }

  /deep/ .el-dialog__header {
    text-align: left;
    font-size: 18px;
    color: #333333;
    padding-bottom: 18px;
    border-bottom: solid 1px #e5e5e5;
  }
}

.step-detail {
  display: flex;
  padding-top: 10px;

  .step-content-image {
    width: 214px;
    height: 156px;
    overflow: hidden;
  }
}

.status-price {
  color: #666;
}

.apply-tip {
  color: #999999;
  font-size: 14px;
  line-height: 32px;
}

.apply-order {
  margin-top: 30px;
  padding: 20px 0;
  font-size: 14px;
  border-top: 1px solid #e8e8e8;
  color: #555555;
  line-height: 32px;

  h2 {
    font-size: 16px;
    font-weight: 400;
    color: #333333;
  }

  .name {
    display: inline-block;
    width: 128px;
  }

  .value {
    display: inline-block;
  }
}

.gzry {
  color: #333;
  padding-top: 10px;
  font-weight: bold;

  span {
    display: inline-block;
    margin-right: 5px;
  }
}

.feeBox {
  padding-left: 20px;
  padding-top: 10px;
  margin-bottom: 20px;
  color: #333;

  .name {
    font-size: 16px;
    margin-top: 10px;
  }

  .num {
    color: rgb(64, 158, 255);
  }

  .fee {
    color: rgba(233, 157, 66, 1);
  }

  .total {
    font-size: 20px;
    font-weight: bold;
  }
}

/deep/ .el-table__fixed-right::before,
/deep/ .el-table__fixed::before {
  display: none;
}

.content-bg1 {
  background: #f2f2f2;
  padding: 10px;
  margin-bottom: 20px;
}

.content-bg2 {
  background: #def0fa;
  padding: 10px;
}

.content-bg3 {
  background: #f0f9eb;
  padding: 10px;
  margin-top: 20px;
}

//::v-deep .el-table td{
//  padding: 2px 0;
//}
::v-deep .page-bar {
  margin-bottom: 0px;
  padding: 10px;
  margin-left: -22px;
  background: #fff;
}

/deep/ .el-table td {
  height: 45px !important;
  line-height: 45px !important;
  padding: 2px 0;
}
</style>
